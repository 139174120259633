import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const CarbonffPage = () => {
  return (
    <>
      <div className="carbonprintbanerSection">
        <video autoPlay muted loop playsInline className="video-background">
          <source
            src="https://copyadatafromawstoazure.blob.core.windows.net/uploads/12.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="banertextgroup banneroverlay words_back">
          <h1>Carbon Footprint Frameworks</h1>
        </div>
      </div>
      <Container>
        <div>
          <Row className="serve">
            <Col>
              <div className="First-para">
                <h5 className="mb-4">ECOSYSTEM</h5>
                <h6>
                  <strong>
                    Stage-Wise Approach for Reporting Carbon Footprint
                  </strong>
                </h6>
                <p>
                  <ul>
                    <li>
                      Identify and list all operations under your preview that
                      release GHGs{' '}
                    </li>
                    <li>
                      Categorise each of the activity into one of the 3 scopes
                    </li>
                    <li>
                      {' '}
                      Gather data on the level each activity for a period of one
                      year
                    </li>
                    <li>
                      The GHG emission is the product of your operational data
                      and the emission factor for the activity
                    </li>
                    <li>
                      {' '}
                      Report GHG emissions and benchmark against a selected base
                      year
                    </li>
                    <li>
                      Get the report structured and audited by authenticated
                      third parties
                    </li>
                  </ul>
                </p>
                <h6>
                  <strong>Carbon Footprint Standards</strong>
                </h6>
                <div className="Anchor_12">
                  <ul>
                    <li>
                      <a
                        href="https://verra.org/programs/verified-carbon-standard/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Verified Carbon Standard (VCS)
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.goldstandard.org/articles/gold-standard-emission-reductions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Gold Standard Verified Emission Reduction (GS VER)
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.offsetguide.org/understanding-carbon-offsets/carbon-offset-programs/voluntary-offset-programs/"
                        target="_blank"
                        rel="noopener  noreferrer"
                      >
                        Voluntary Offset Standard
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://verra.org/programs/ccbs/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Climate, Community, Biodiversity (CCB) Standards
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.socialcarbon.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Social Carbon
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://verra.org/programs/sd-verified-impact-standard/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sustainable Development Verified Impact Standard (SD
                        VISta)
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ padding: '0px 0' }}>
            <div className="text-center mb-5"></div>
            <Row>
              <Col md={4} className="model_swction_tab1 p-0">
                <div className="banertextgroup banneroverlays">
                  <h2>
                    <a href="/reporting">ESG STRATEGY</a>
                  </h2>
                </div>
              </Col>
              <Col md={4} className="model_swction_tab2 p-0">
                <div className="banertextgroup banneroverlays">
                  <h2>
                    <a href="/supplychain">SUPPLY CHAIN</a>
                  </h2>
                </div>
              </Col>
              <Col md={4} className="model_swction_tab3 p-0">
                <div className="banertextgroup banneroverlays">
                  <h2>
                    {' '}
                    <a href="/footprint">CARBON FOOTPRINT</a>
                  </h2>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  )
}
export default CarbonffPage
