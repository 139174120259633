import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import './homepage.css'
import Middle1 from './Middle1';
import Middle2 from './Middle2';
import Middle3 from './Middle3';

const HomePage = () => {
    return (
        <>
            <Container fluid="fluid">
                <Row>
                    <Col className='p-0'>
                        <div className='banerSection'>
                            <video autoPlay muted loop className='video-background'>
                                <source src="https://copyadatafromawstoazure.blob.core.windows.net/uploads/f6.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className='banertextgroup banneroverlay words_back'>
                                <h1 className='welcome_test'><span style={{ fontSize: 30 }}>Welcome To</span> <br />
                                    Reporting Intelligence Unit
                                </h1>
                            </div>
                        </div>
                        <Middle1 />
                        <Middle2 />
                        <Middle3 />
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default HomePage;