import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image12 from '../../../img/logo.png'
import { NavLink } from 'react-router-dom'
// import './footer.css';
const Footer = () => {
  return (
    <>
      <div className="Below-point">
        <Container>
          <Row>
            <Col md={3}>
              <div className="footjs">
                <img className="mb-4 w-75" src={Image12} alt="" />
              </div>
              <p>Automating sustainability reporting across supply chains</p>
            </Col>
            <Col md={3}>
              <h2>Support</h2>
              <div className="Quicklink-class">
                <ul>
                  <li>
                    <NavLink as={NavLink} to="/privacy-p">
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/terms-c">
                      Terms & Conditions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/customerP">
                      Customer Support Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/cancellation">
                      Cancellation & Refund Policy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <h2>Quick Links</h2>
              <div className="Quicklink-class">
                <ul>
                  <li>
                    <NavLink as={NavLink} to="/">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/about">
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/contact">
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/blog">
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={NavLink} to="/annual-filigs">
                      Annual Filings
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <h2>Contact</h2>
              <div className="Quicklink-class">
                <ul>
                  <li>
                    <h>Gothenburg, Sweden</h>
                  </li>
                  <li>
                    <a href="tel:8884966651">+46 - 708943293</a>
                  </li>
               
                </ul>
              </div>

              <div className="Quicklink-class">
                <ul>
                  <li>
                    <h>Bengaluru, India</h>
                  </li>
                  <li>
                    <a href="tel:8884966651">(+91) 973 – 875 – 7221</a>
                  </li>
                  <li>
                    <a href="mailto:contact@riu.ai">
                      contact@riu.ai
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <div className="d-flex justify-content-center align-items-center bottom_footer">
            <p>© Copyright 2023 – All rights reserved.</p>
          </div>
        </Container>
      </div>
    </>
  )
}
export default Footer
