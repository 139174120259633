import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import Image29 from '../../img/supplyesg.png';
// import bgImage from '../../video/supplychain1.webm';
import CarauselPage from './Carousal'
const SupplyChainPage = () => {
  return (
    <>
      <div className="supplybanerSection">
        <video autoPlay muted loop className="video-background">
          <source
            src="https://copyadatafromawstoazure.blob.core.windows.net/uploads/f7.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="banertextgroup banneroverlay words_back">
          <h1>ESG Supply Chain Strategy</h1>
        </div>
      </div>
      <Container>
        <div>
          <Row className="serve d-flex align-items-center">
            <Col lg={6} md={12}>
              <div className="First-para">
                <h5>ECOSYSTEM</h5>
                <h2>Supply Chain Level Strategy</h2>
                <p style={{ textAlign: 'justify' }}>
                  ESG improvement seldom occurs at a firm level. You need to
                  improve the ESG performance with your entire supply chain
                  working as a cohesive unit. With the current software and
                  consulting set up, the firm-level perspective of ESG risks and
                  impacts with manual, error-prone data authentication processes
                  leave your supply chains vulnerable to greenwashing practices
                  and solicit poor confidence in your reports.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  We are here to bridge this gap through Collaborative Reporting
                  – ESG data from across your supply chain is collated onto a
                  single dashboard for seamless monitoring, Collective Vision –
                  We aid you in setting holistic targets to your entire supply
                  chain based on the Collaborative Report, and finally ensure
                  the highest levels of stakeholder confidence – We enable total
                  visibility into all foolproof processes involved in data
                  collection, compilation, and reporting.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  We are incorporating Blockchain and Machine Learning
                  technologies to make ESG data sharing across supply chains: 1.
                  automatic – to reduce user inputs, 2. secure – to build trust
                  among supply chain partners, and 3. transparent – to eliminate
                  greenwashing and enhance stakeholder confidence.
                </p>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div>
                <video
                  className="w-100"
                  autoPlay
                  loop
                  muted
                  style={{ borderRadius: '0.8rem' }}
                >
                  <source
                    src="https://copyadatafromawstoazure.blob.core.windows.net/uploads/f3.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Col>
          </Row>
          <div style={{ padding: '0px 0' }}>
            <div className="text-center mb-5"></div>
            <CarauselPage
              heading1="Data Collection"
              heading2="Report Generation"
              heading3="Consulting"
              sliderOneDescp="Assess a wide range of ESG and non-ESG risks prevalent in your supply chain. Use our dashboard to monitor risks as they arise and identify ESG trends in your suppliers’ industry to have better business continuity insights."
              sliderTwoDescp="Based the regulatory environment and business interests, we help you choose the right ESG framework to form synergetic reports of your supply chain that are clear, concise, and comprehensive to the stakeholders in understanding the risks and areas of improvement for your supply chain."
              sliderThreeDescp="Reach out to experts of your domain who are readily available for your consulting needs anytime."
            />
          </div>
        </div>
      </Container>
    </>
  )
}
export default SupplyChainPage
